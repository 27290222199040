import React from 'react';
import './BaseLayout.scss';
import {useHistory} from "react-router-dom";
import {subpages} from "../constants";

const BaseLayout: React.FC = ({ children }) => {
    const history = useHistory();

    return (
        <>
            <div id="baselayout" className="baseLayout">
                <header role="banner" className="header">
                    <div className="content">
                        <div className="kkContainer" onClick={()=> history.push("/")}>
                            {/*<div className="logoContainer"/>*/}
                            <div className={"heading"}>
                                Kohlmann GmbH
                            </div>
                        </div>
                    </div>
                </header>
                <div id="baselayout_content_container" className="content mainContent">
                    {children}
                </div>
                <div className="footer">
                    <div className="content text">
                        <div>
                            Copyright &copy; Kohlmann GmbH 2021
                        </div>
                        <div className="clickable" onClick={()=> history.push(subpages.IMPRESSUM)}>
                            Impressum
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BaseLayout;
