import React, { createContext } from 'react';

export interface BaseLayoutContextType {
}

export const BaseLayoutContext = createContext<BaseLayoutContextType>(({} as any) as BaseLayoutContextType);

export const BaseLayoutContextProviderFactory = () => {

    return {};
};

interface BaseLayoutContextProviderProps {
    provider?: BaseLayoutContextType;
}

export const BaseLayoutContextProvider: React.FC<BaseLayoutContextProviderProps> = ({
    children,
    provider = BaseLayoutContextProviderFactory(),
}) => <BaseLayoutContext.Provider value={provider}>{children}</BaseLayoutContext.Provider>;
