import React from 'react';
import './../Dashboard.scss';
import {GiSmartphone, GrMail} from "react-icons/all";
import IconBox from "../../IconBox/IconBox";

interface IconBoxWithHeaderProps {
}

const RightContent: React.FC<IconBoxWithHeaderProps> = (props) => {
    return (
        <div className="subBoxContainer scrolling">
            <div className="column column2 smalltext">
                <IconBox header="Anschrift" iconType={GrMail}>
                    <p>
                        Kohlmann GmbH<br/>
                        Froebelstraße 29<br/>
                        D-59227 Ahlen
                    </p>
                </IconBox>
                <IconBox header="Kontaktmöglichkeiten" iconType={GiSmartphone}>
                    <p>
                        Tel.: +49 (0) 2528 / 901 627 <br/>
                        Fax.: +49 (0) 2528 / 901 802 <br/>
                        Mobil: +49 (0) 170 / 34 35 33 2 <br/>
                        E-Mail: info@kohlmann-konstruktionen.com <br/>
                    </p>
                </IconBox>
            </div>
            <div className="subContainerElement forMap shadowForBox boxHeading" style={{textAlign: "center"}}>
                <div style={{marginBottom: "1em"}}>Anfahrt</div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1744.5046869195342!2d7.939980939369371!3d51.80429340980837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b99cfaf732d81f%3A0xa070f2b2a3cd638e!2sKohlmann%20UG%20(haftungsbeschr%C3%A4nkt)!5e0!3m2!1sde!2sde!4v1597962638721!5m2!1sde!2sde"
                    width="100%" height="85%" frameBorder="0" style={{border: 0}} allowFullScreen={true}
                    aria-hidden="false" tabIndex={0}
                    title={"GoogleMapsIFrame"}/>
            </div>
        </div>
    );
};

export default RightContent;
