import React from 'react';
import './IconBoxWithHeader.scss';
import {IconContext, IconType} from "react-icons";

interface IconBoxWithHeaderProps {
    iconType: IconType;
    header: string;
    target?: string;
}

const IconBoxWithHeader: React.FC<IconBoxWithHeaderProps> = (props) => {

    const navigateToTarget = () => {
        //Todo reactivate when subpages ready
    };

    return (
        <div
            className={"subContainerElement withIcon shadowForBox column delay" /*+ (props.target ? " clickable" : "")*/}
            onClick={navigateToTarget}
        >
            <div className="row">
                <IconContext.Provider
                    value={{color: "white", size: "8vh", className: "padding", style: {borderColor: "white"}}}>
                    <props.iconType/>
                </IconContext.Provider>
                <div className={"boxHeading"}>
                    <p>{props.header}</p>
                </div>
            </div>
            <div className={"smalltext"}>
                {props.children}
            </div>
        </div>
    );
};

export default IconBoxWithHeader;
