import React from 'react';
import {Router} from 'react-router';
import {Route} from 'react-router-dom';
import './App.css';
import BaseLayout from "./BaseLayout/BaseLayout";
import {BaseLayoutContextProvider} from './BaseLayout/BaseLayoutContext';
import Dashboard from "./Dashboard/Dashboard";
import {createBrowserHistory} from 'history';
import Impressum from "./Impressum/Impressum";

export const App: React.FC = () => {
    const history = createBrowserHistory();

    history.listen((location, action) => {
        console.log(action, location.pathname, location.state);
    });

    return (
        <Router history={history}>
            <BaseLayoutContextProvider>
                <BaseLayout>
                    <Route path="/" exact={true} component={Dashboard}/>
                    <Route path="/impressum" component={Impressum}/>
                </BaseLayout>
            </BaseLayoutContextProvider>
        </Router>
    );
}


export default App;
