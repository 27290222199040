import React, {useEffect, useState} from 'react';
import './../Dashboard.scss';
import {CSSTransition} from 'react-transition-group';

interface ContentContainerProps {
    maximize: boolean;
    minimize: boolean;
    backgroundImageTag: string;
    title: string;
}

const ContentContainer: React.FC<ContentContainerProps> = (props) => {
    const [transitionFinished, setTransitionFinished] = useState<boolean>(false);

    useEffect(() => {
        setTransitionFinished(false);
        if (props.maximize) {
            setTimeout(() => {
                setTransitionFinished(true);
            }, 500);
        }
    }, [props.maximize]);

    const showContent = transitionFinished && props.maximize;

    return (
        <div className={"imageContainer " + props.backgroundImageTag}>
            {!props.minimize &&
            <div className="textbox shadowForBox siteHeading">
                {props.title}
            </div>
            }
            {showContent && <CSSTransition appear={true} in={true} timeout={200} classNames="content">
                <>
                    {props.children}
                </>
            </CSSTransition>
            }
        </div>
    );
};

export default ContentContainer;
