import React from 'react';
import './Impressum.scss';

const Impressum: React.FC = () => {
    return (
        <>
            <div className="impressumMiddleContainer">
                <div className={"textLeft"}>
                    <div className={"textCenter"}>
                        <h1>
                            Impressum
                        </h1>
                    </div>

                    <p>Angaben gem&auml;&szlig; &sect; 5 TMG</p>


                    <h4>Geschäftsführer:</h4>
                    <p>
                        Thomas Kohlmann<br/>
                        Froebelstraße 29<br/>
                        59227 Ahlen
                    </p>


                    <h4>Kontakt:</h4>
                    <p>
                        Telefon: +49 (0) 2528 / 901 627<br/>
                        Mobil: +49 (0) 170 / 34 35 33 2<br/>
                        E-Mail: info@kohlmann-konstruktionen.com
                    </p>


                    <h4>Registereintrag:</h4>
                    <p>
                        Eintragung im Registergericht: Amtsgericht Münster<br/>
                        Registernummer: HRB 12385
                    </p>


                    <h4>Umsatzsteuer-ID:</h4>
                    <p>
                        Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect;27a Umsatzsteuergesetz:
                        DE270540102. <br/>
                        Steuer-Nr.: 304 5938 1326
                    </p>

                    <h2>Haftungsausschluss:</h2>


                    <h4>Haftung f&uuml;r Inhalte </h4>

                    <p>Die Inhalte unserer Seiten wurden mit gr&ouml;&szlig;ter Sorgfalt erstellt. F&uuml;r die
                        Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t der Inhalte k&ouml;nnen wir jedoch
                        keine
                        Gew&auml;hr &uuml;bernehmen. Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1
                        TMG
                        f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                        Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                        verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen
                        oder
                        nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                        allgemeinen
                        Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab
                        dem
                        Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von
                        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </p>
                    <h4>Haftung f&uuml;r Links</h4>

                    <p>Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
                        Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine
                        Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige
                        Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
                        der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige
                        Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
                        Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
                        nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                        entfernen.
                    </p>

                    <h4>Urheberrecht</h4>

                    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                        deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
                        Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen
                        Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
                        f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
                        Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
                        Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                        Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                    </p>

                    <h4>Datenschutz</h4>

                    <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten m&ouml;glich.
                        Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder
                        eMail-Adressen) erhoben werden, erfolgt dies, soweit m&ouml;glich, stets auf freiwilliger Basis.
                        Diese Daten werden ohne Ihre ausdr&uuml;ckliche Zustimmung nicht an Dritte weitergegeben.<br/>
                        Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation
                        per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem
                        Zugriff durch Dritte ist nicht m&ouml;glich.<br/>
                        Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten durch Dritte
                        zur &Uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und
                        Informationsmaterialien wird hiermit ausdr&uuml;cklich widersprochen. Die Betreiber der Seiten
                        behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von
                        Werbeinformationen, etwa durch Spam-Mails, vor.</p>


                    {/* <p>
                        <h4>Google Analytics</h4>

                    <p> Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (''Google'').
                        Google Analytics verwendet sog. ''Cookies'', Textdateien, die auf Ihrem Computer gespeichert
                        werden und die eine Analyse der Benutzung der Website durch Sie erm&ouml;glicht. Die durch den
                        Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website (einschlie&szlig;lich
                        Ihrer IP-Adresse) wird an einen Server von Google in den USA &uuml;bertragen und dort
                        gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten,
                        um Reports &uuml;ber die Websiteaktivit&auml;ten f&uuml;r die Websitebetreiber zusammenzustellen
                        und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu
                        erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte &uuml;bertragen, sofern
                        dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten.
                        Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen.
                        Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser
                        Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
                        nicht s&auml;mtliche Funktionen dieser Website voll umf&auml;nglich nutzen k&ouml;nnen. Durch
                        die Nutzung dieser Website erkl&auml;ren Sie sich mit der Bearbeitung der &uuml;ber Sie
                        erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten
                        Zweck einverstanden.</p>

                    </p>*/}

                    <h4>Impressum vom Impressum Generator der Kanzlei Hasselbach, Bonn</h4>

                </div>
            </div>
        </>
    );
};

export default Impressum;
