import React from 'react';
import './../Dashboard.scss';
import {
    HiUserGroup, RiComputerLine, RiHomeGearLine
} from "react-icons/all";
import IconBoxWithHeader from "../../IconBoxWithHeader/IconBoxWithHeader";

interface IconBoxWithHeaderProps {
}

const LeftContent: React.FC<IconBoxWithHeaderProps> = (props) => {
    return (
        <div className="subBoxContainer scrolling">
            <IconBoxWithHeader header={"CAD Software"} iconType={RiHomeGearLine}>
                <div style={{width: "35em"}}>
                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                        <div className={"bordered"}>Inventor</div>
                        <div className={"bordered"}>AutoCAD Architecture</div>
                        <div className={"bordered"}>AutoCAD Mechanical</div>
                        <div className={"bordered"}>ReCap</div>
                        <div className={"bordered"}>AutoCAD Electrical</div>
                        <div className={"bordered"}>Navis Works</div>
                    </div>
                </div>
            </IconBoxWithHeader>
            <IconBoxWithHeader header={"Kunden"} iconType={HiUserGroup}>
                <div style={{width: "35em"}}>
                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                        <div className={"bordered"}>Zementindustrie</div>
                        <div className={"bordered"}>Baustoffindustrie</div>
                        <div className={"bordered"}>Gießereien</div>
                        <div className={"bordered"}>Lebensmittelindustrie</div>
                        <div className={"bordered"}>Chemische Industrie</div>
                    </div>
                </div>
            </IconBoxWithHeader>
            <IconBoxWithHeader header={"Sonstige Technologien"} iconType={RiComputerLine}>
                <div style={{width: "40em"}}>
                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                        <div className={"bordered"}>Microsoft Office</div>
                        <div className={"bordered"}>Owncloud</div>
                    </div>
                    <div className={"smalltext"} style={{margin: "1em"}}>
                        <p>
                            Datensicherheit nimmt bei unserer Arbeit einen hohen Stellenwert ein: <br/>
                            Für die Kommunikation und den Datenaustausch nutzen wir SSL zur Ende-zu-Ende-Verschlüsselung
                            und speichern Daten ausschließlich auf unseren eigenen lokalen Servern.
                        </p>
                    </div>
                </div>
            </IconBoxWithHeader>
        </div>
    );
};

export default LeftContent;
