import React from 'react';
import './../Dashboard.scss';
import {AiFillFolderOpen, GoGear} from "react-icons/all";
import IconBoxWithHeader from "../../IconBoxWithHeader/IconBoxWithHeader";
import {subpages} from "../../constants";

const MiddleContent: React.FC = () => {
    return (
        <div className="subBoxContainer scrolling">
            <div style={{maxWidth: "45%"}}>
                <IconBoxWithHeader header={"Dienstleistungen"} iconType={AiFillFolderOpen}
                                   target={subpages.DIENSTLEISTUNGEN}>
                    <div>
                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                            <div className={"bordered"}>Neukonstruktionen</div>
                            <div className={"bordered"}>Produktentwicklung</div>
                            <div className={"bordered"}>Anlagenplanung</div>
                            <div className={"bordered"}>3D-Vermessung</div>
                            <div className={"bordered"}>Maßaufnahmen gebrauchter/vorhandener Maschinen und Anlagen</div>
                            <div className={"bordered"}>Datenauswertung von 3D-Maßaufnahmen</div>
                            <div className={"bordered"}>Erstellung von Fließbildern</div>
                        </div>
                    </div>
                </IconBoxWithHeader>
            </div>
            <div style={{maxWidth: "45%"}}>
                <IconBoxWithHeader header={"Maschinen- und Anlagenkomponenten"} iconType={GoGear}
                                   target={subpages.KOMPONENTEN}>
                    <div>
                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                            <div className={"bordered"}>Schau- und Inspektionsklappen</div>
                            <div className={"bordered"}>Mechanische Fördertechnik</div>
                            <div className={"bordered"}>Luftrinnensysteme</div>
                            <div className={"bordered"}>Drosselklappen</div>
                            <div className={"bordered"}>Probenehmer</div>
                        </div>
                    </div>
                </IconBoxWithHeader>
            </div>
        </div>
    );
};

export default MiddleContent;
