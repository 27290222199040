import React, {useState} from 'react';
import './Dashboard.scss';
import LeftContent from "./Content/LeftContent";
import MiddleContent from "./Content/MiddleContent";
import RightContent from "./Content/RightContent";
import ContentContainer from "./Content/ContentContainer";
import Modal from "./Popup/Modal";

const Dashboard: React.FC = () => {
    const [hoverOverLeft, setHoverOverLeft] = useState<boolean>(false);
    const [hoverOverMiddle, setHoverOverMiddle] = useState<boolean>(false);
    const [hoverOverRight, setHoverOverRight] = useState<boolean>(false);

    const isMobile = window.matchMedia('(max-width: 640px)').matches;

    if (isMobile) {
        console.log("Showing mobile website version");
        return <>
            <div className="leftContainer scrolling">
                <Modal/>
                <RightContent/>
            </div>
        </>
    }

    return (
        <>
            <div
                className={"linkContainer " + (hoverOverLeft ? "wide " : " ") + (hoverOverMiddle || hoverOverRight ? "small " : " ")}
                onMouseEnter={() => setHoverOverLeft(true)} onMouseLeave={() => setHoverOverLeft(false)}
            >
                <ContentContainer
                    maximize={hoverOverLeft}
                    minimize={hoverOverRight || hoverOverMiddle}
                    backgroundImageTag={"leftContainer"}
                    title={"Unternehmen"}
                >
                    <LeftContent/>
                </ContentContainer>
            </div>
            <div
                className={"linkContainer " + (hoverOverMiddle ? "wide " : " ") + (hoverOverLeft || hoverOverRight ? "small " : " ")}
                onMouseEnter={() => setHoverOverMiddle(true)} onMouseLeave={() => setHoverOverMiddle(false)}>
                <ContentContainer
                    maximize={hoverOverMiddle}
                    minimize={hoverOverRight || hoverOverLeft}
                    backgroundImageTag={"middleContainer"}
                    title={"Leistungen & Produkte"}
                >
                    <MiddleContent/>
                </ContentContainer>
            </div>
            <div
                className={"linkContainer " + (hoverOverRight ? "wide " : " ") + (hoverOverMiddle || hoverOverLeft ? "small " : " ")}
                onMouseEnter={() => setHoverOverRight(true)} onMouseLeave={() => setHoverOverRight(false)}>
                <ContentContainer
                    maximize={hoverOverRight}
                    minimize={hoverOverMiddle || hoverOverLeft}
                    backgroundImageTag={"rightContainer"}
                    title={"Kontakt"}
                >
                    <RightContent/>
                </ContentContainer>
            </div>
        </>
    );
};

export default Dashboard;
