import React from 'react';
import './IconBox.scss';
import {IconContext, IconType} from "react-icons";

interface IconBoxWithHeaderProps {
    iconType: IconType;
    header: string;
}

const IconBox: React.FC<IconBoxWithHeaderProps> = (props) => {
    return (
        <div className="subContainerElement withIcon shadowForBox">
            <IconContext.Provider value={{color: "white", size: "12vh", className: "padding"}}>
                <props.iconType/>
            </IconContext.Provider>
            <div>
                <div className={"boxHeading"}>
                    {props.header}
                </div>
                <div className={"smalltext"}>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default IconBox;
