import Popup from "reactjs-popup";
import IconBoxWithHeader from "../../IconBoxWithHeader/IconBoxWithHeader";
import {FaExclamation} from "react-icons/all";
import React from "react";

const Modal = () => (
    <Popup open={true} closeOnDocumentClick={true} modal nested overlayStyle={{background: 'rgba(0,0,0,0.5)'}}>
        {
            // @ts-ignore
            close => (
                <div onClick={close}>
                    <IconBoxWithHeader header={"Mobile Version"} iconType={FaExclamation}>
                        <p>
                            Die mobile Version dieser Website ist auf unsere Kontaktdaten reduziert.<br/>
                            Besuchen sie für alle weiteren Informationen bitte die Desktopversion.
                        </p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <button className={"bordered"} onClick={close}>
                                Verstanden!
                            </button>
                        </div>
                    </IconBoxWithHeader>
                </div>
            )}
    </Popup>
);

export default Modal
